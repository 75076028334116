import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import delay from "lodash.delay"
import axios from "axios"
import { LeviPage } from "components/Text"
import LevisTokenViewer from "components/LevisTokenViewer"
import { ReactComponent as FiveOhOneSVG } from "svg/501Logo.svg"
import { ReactComponent as MobileNavArrowSVG } from "svg/levisMobileNavArrow.svg"
import Colors from "styles/Colors"
import { Levis as LevisColors } from "styles/Colors"
import { FluidImage } from "src/Types"
import media from "src/styles/media"

type Props = {
  data: {
    naomi1: FluidImage
    naomi2: FluidImage
    naomiHS: FluidImage
    naomiM: FluidImage
    marcusHS: FluidImage
    haileyBHS: FluidImage
    haileyB1: FluidImage
    haileyB2: FluidImage
    haileyM: FluidImage
    jadenSHS: FluidImage
    jadenS1: FluidImage
    jadenS2: FluidImage
    jadenM: FluidImage
    emmaCHS: FluidImage
    emmaC1: FluidImage
    emmaC2: FluidImage
    emmaM: FluidImage
    shaiGHS: FluidImage
    shaiG1: FluidImage
    shaiG2: FluidImage
    shaiM: FluidImage
    barbieFHS: FluidImage
    barbieF1: FluidImage
    barbieF2: FluidImage
    barbieM: FluidImage
  }
}

const Hero: React.FC<Props> = ({ data }) => {
  const [current, setCurrent] = useState(100)
  const [mobileCurrent, setMobileCurrent] = useState(0)
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [tokens, setTokens] = useState(null)
  const wrapperRef = useRef(null)
  const openTokenOffsetRef = useRef(-30)
  const auctionTokenRef: any = useRef()
  const auctionTokenOffsetRef = useRef(-20)
  const celebCard = useRef(null)
  const [dataIsSet, setDataIsSet] = useState(false)
  const [celebData, setCelebData] = useState([
    {
      name: ["Naomi", "Osaka"],
      image1: data.naomi1.childImageSharp.fluid,
      image2: data.naomi2.childImageSharp.fluid,
      navImage: data.naomiHS.childImageSharp.fluid,
      mobileImage: data.naomiM.childImageSharp.fluid,
      issue: "NFT 501 ISSUED:05.20.2021",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      active: true,
      width: "23.5vw",
      tabletWidth: "33vw",
      mobileWidth: "56vw",
      firstName: true,
      productID: "0386708d-3ee6-4b42-b24e-bc8942b04815",
      auctionProductID: "8f25612c-cc71-41dd-9a02-3c36d949475c",
    },
  ])

  const onMouseMove = e => {
    if (window.innerWidth > 1024) {
      let openToken = document.getElementById(
        `openToken${current === 10 ? "0" : current}`
      )

      if (openToken) {
        parallax(e, openToken, openTokenOffsetRef.current)
        parallax(e, auctionTokenRef.current, auctionTokenOffsetRef.current)
      }
    }
  }

  const parallax = (e, target, movement) => {
    const relX = e.pageX - wrapperRef.current.offsetLeft
    const relY = e.pageY - wrapperRef.current.offsetTop
    const moveX =
      ((relX - wrapperRef.current.offsetWidth / 2) /
        wrapperRef.current.offsetWidth) *
      movement
    const moveY =
      ((relY - wrapperRef.current.offsetHeight / 2) /
        wrapperRef.current.offsetHeight) *
      movement
    gsap.to(target, {
      x: moveX,
      y: moveY,
      rotateY:
        Math.asin((moveX * Math.sin(90)) / (movement * 3)) * (180 / Math.PI),
      rotateX:
        Math.asin((moveY * Math.sin(90)) / (movement * 5)) * (180 / Math.PI),
    })
  }

  // MARQUEE ANIMATION
  useEffect(() => {
    const tl = gsap.timeline({
      repeat: -1,
    })
    tl.to(".f01__text", {
      x: "-100%",
      ease: "none",
      duration: window.innerWidth > 1200 ? 35 : 20,
    }).to(".f01__text", { x: 0, duration: 0 })
  }, [])

  const celebTokens = useCallback(() => {
    if (tokens) {
      return celebData.map((celeb, i) => {
        return (
          <FragmentWrap
            key={celeb.name[0]}
            visible={current === i}
            initial={i === 0 && current === 10}
            dual={i === 0}
          >
            {i === 0 && (
              <TokenWrap ref={auctionTokenRef}>
                <LevisTokenViewer
                  product={
                    tokens.filter(
                      token => token.id === celeb.auctionProductID
                    )[0]
                  }
                  current={current === i}
                  key={i}
                  first={current === 10}
                />
              </TokenWrap>
            )}
            <TokenWrap id={`openToken${i}`}>
              <LevisTokenViewer
                product={
                  tokens.filter(token => token.id === celeb.productID)[0]
                }
                current={current === i}
                key={i}
              />
            </TokenWrap>
          </FragmentWrap>
        )
      })
    }
  }, [current, tokens])

  useEffect(() => {
    axios
      .get(
        "https://api.bitski.com/v1/stores/50ed84ab-cc60-4079-953c-7f94d0896934/products"
      )
      .then(res => {
        setTokens(res.data.products)
        celebData.map((celeb, i) => {
          if (i === 1) return
          let celebToken = res.data.products.filter(
            token => token.id === celeb.productID
          )[0]
          let copy = [...celebData]
          copy[i].active =
            celebToken &&
            new Date(celebToken.liveAt).getTime() < new Date().getTime()
          setCelebData(copy)
        })
      })
      .then(() => {
        setDataIsSet(true)
      })

      .catch(error => console.error(error))
  }, [])

  const celebButtons = celebData.map((celeb, i) => {
    return (
      <CelebButton
        key={i}
        disabled={!celeb.active}
        active={celeb.active}
        onClick={() => handleClick(celeb.active, i)}
      >
        <Headshot isCurrent={current === i || current === i + 10}>
          <Img fluid={celeb.navImage} />
        </Headshot>
        <Name>{celeb.name[0]}</Name>
        <Name>{celeb.name[1]}</Name>
      </CelebButton>
    )
  })

  const celebCards = celebData.map((celeb, i) => {
    let tablet: boolean
    if (typeof window !== "undefined") {
      tablet = window.innerWidth < 1025
    }

    return (
      <CelebCard
        className={`${celeb.name[0]}-card`}
        initial={current > 9}
        key={`card${i}`}
        ref={celebCard}
      >
        <TitleWrapper
          titleWidth={tablet ? celeb.tabletWidth : celeb.width}
          indented={celeb.firstName}
          animate={current === i}
          initial={current === i + 10}
          className={`${celeb.name[0]}-title`}
        >
          <Title>{celeb.name[0]}</Title>
          <Title>{celeb.name[1]}</Title>
        </TitleWrapper>
        <CelebImagesWrapper>
          <Image1
            animate={current === i}
            className={`${celeb.name[0]}-img1`}
            initial={current === i + 10}
          >
            <Img fluid={celeb.image1} />
          </Image1>
          <Image2
            animate={current === i}
            className={`${celeb.name[0]}-img2`}
            initial={current === i + 10}
          >
            <Img fluid={celeb.image2} />
          </Image2>
          <IssueNo visible={current === i || (current === 10 && i === 0)}>
            NFT 501 Issued: 05.20.2021
          </IssueNo>
          <CelebText
            className={`${celeb.name[0]}-text`}
            animate={current === i}
            initial={current === 10 && i === 0}
          >
            {celeb.text}
          </CelebText>
        </CelebImagesWrapper>
      </CelebCard>
    )
  })

  const mobileCelebCards = celebData.map((celeb, i) => {
    if (celeb.active)
      return (
        <MobileCelebCard key={`mobileCard${i}`} id={`mobileCard${i}`}>
          <MobileImage>
            <Img fluid={celeb.mobileImage} />
          </MobileImage>
          <MobileTitleWrapper
            width={celeb.mobileWidth}
            indented={celeb.firstName}
          >
            <Title>{celeb.name[0]}</Title>
            <Title>{celeb.name[1]}</Title>
          </MobileTitleWrapper>
          <MobileCelebText>{celeb.text}</MobileCelebText>
          {tokens && celeb.auctionProductID && (
            <LevisTokenViewer
              product={
                tokens.filter(token => token.id === celeb.auctionProductID)[0]
              }
            />
          )}
          {tokens ? (
            <LevisTokenViewer
              product={tokens.filter(token => token.id === celeb.productID)[0]}
            />
          ) : (
            <MobileTokenPlaceholder dual={i === 0} />
          )}
        </MobileCelebCard>
      )
  })

  useEffect(() => {
    for (let i = 0; i < 7; i++) {
      if (i !== 1) {
        ScrollTrigger.create({
          trigger: `#mobileCard${i}`,
          endTrigger: `#mobileCard${i}`,
          start: "top 130px",
          end: "bottom bottom-=130px",
          onEnter: () => setMobileCurrent(i),
          onEnterBack: () => setMobileCurrent(i),
        })
      }
    }
  }, [])

  const handleClick = (isActive: boolean, num: number) => {
    if (isActive) {
      setCurrent(num)
      if (window.innerWidth > 767 && window.innerWidth < 1025) {
        gsap.to(".bitski_levi-roster", {
          duration: 0.5,
          scrollTo: { x: num > 3 ? "max" : 0 },
        })
      }
    }
  }

  useEffect(() => {
    if (tokens) {
      if (dataIsSet) {
        delay(() => setCurrent(10), 0)
      }
    }
  }, [tokens, celebData, dataIsSet])

  const handleMobileNavClick = () => setMobileNavOpen(!mobileNavOpen)

  const handleMobileLink = num => {
    if (celebData[num].active) {
      handleMobileNavClick()
      gsap.to(window, { duration: 0.6, scrollTo: `#mobileCard${num}` })
    }
  }

  return (
    <Wrapper ref={wrapperRef} onMouseMove={onMouseMove}>
      <LogoWrapper>
        <span>NFT's</span> <FiveOhOne />
      </LogoWrapper>
      {celebCards}

      {celebTokens()}

      <CelebRoster className="bitski_levi-roster">
        <span> {celebButtons}</span>
      </CelebRoster>

      <MobileSection>{mobileCelebCards}</MobileSection>
      <Marquee>
        <MarqueeText className="f01__text">
          <span>
            <FiveOhOne />
          </span>
          Levi’s® has entered the NFT world like never before
          <span>
            <FiveOhOne />
          </span>
          Levi’s® has entered the NFT world like never before
          <span>
            <FiveOhOne />
          </span>
          Levi’s® has entered the NFT world like never before
        </MarqueeText>
        <MarqueeText className="f01__text">
          <span>
            <FiveOhOne />
          </span>
          Levi’s® has entered the NFT world like never before
          <span>
            <FiveOhOne />
          </span>
          Levi’s® has entered the NFT world like never before
          <span>
            <FiveOhOne />
          </span>
          Levi’s® has entered the NFT world like never before
        </MarqueeText>
      </Marquee>
      <MobileNav open={mobileNavOpen}>
        <DropUp>
          <MobileLink
            onClick={() => handleMobileLink(0)}
            current={mobileCurrent === 0}
            active={celebData[0].active}
          >
            Naomi Osaka
          </MobileLink>
        </DropUp>
        <MobileNavSelected onClick={handleMobileNavClick}>
          <SelectedCeleb>
            {celebData[mobileCurrent] &&
              celebData[mobileCurrent].name.join(
                mobileCurrent === 5 ? "" : " "
              )}
          </SelectedCeleb>
          <NavTriangle />
        </MobileNavSelected>
      </MobileNav>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  height: 100%;
  padding: 7vw 7vw 2.1vw 7vw;
  color: white;
  background: ${Colors.cryptoWhite};
  overflow: hidden;
  position: relative;
  max-width: 100%;
  ${media.tablet} {
    /* height: 139.3vw; */
    padding: 10.3vw 7.8vw 2.9vw 7.4vw;

    height: 139.3vw;
  }
  ${media.mobile} {
    > div:not(:first-of-type):not(:last-of-type) {
      display: none;
    }
    padding: 20px 5vw 120px;
    height: auto;
  }
`

const Title = styled.h1`
  ${LeviPage.H1};
  color: ${Colors.charcoal};
  ${media.tablet} {
    font-size: 6.3vw;
  }
  ${media.mobile} {
    font-size: 10.9vw;
    letter-spacing: -0.2vw;
    &:last-of-type {
      margin-bottom: 30px;
    }
  }
`
const TitleWrapper = styled.div<{
  titleWidth: string
  indented: boolean
  animate: boolean
  initial?: boolean
}>`
  position: relative;
  margin-left: 2.6vw;
  width: ${props => props.titleWidth};
  top: ${props => (props.initial ? 0 : props.animate ? 0 : "2.5vw")};
  opacity: ${props => (props.initial ? 1 : props.animate ? 1 : 0)};
  transition: ${props =>
    props.initial ? "1s" : props.animate ? "0.6s" : "opacity 0.2s, top 0.6s"};
  ${Title}:nth-child(1) {
    text-align: ${props => (props.indented ? "left" : "right")};
  }
  ${Title}:nth-child(2) {
    text-align: ${props => (props.indented ? "right" : "left")};
  }
  z-index: 1;
  margin-bottom: 31.3vw;
  ${media.tablet} {
    margin-left: 4.9vw;
    margin-bottom: 116.3vw;
  }
`

const CelebImagesWrapper = styled.figure`
  position: absolute;
  top: 7.2vw;
  left: 0;
  width: 28.8vw;
  height: 24.6vw;
  /* border: 1px solid black; */
  z-index: 0;
  ${media.tablet} {
    top: 10.2vw;
    width: 43.6vw;
    height: 53vw;
  }
`

const Image1 = styled.div<{ animate: boolean; initial?: boolean }>`
  width: 18.5vw;
  height: 24.6vw;
  position: absolute;
  left: 0;
  filter: brightness(0.92);
  top: ${props => (props.initial ? 0 : props.animate ? 0 : "3vw")};
  opacity: ${props => (props.initial ? 1 : props.animate ? 1 : 0)};
  transition-timing-function: cubic-bezier(0.75, 0, 0.25, 1);
  transition: ${props =>
    props.initial
      ? "all 1s 0s"
      : props.animate
      ? "all 0.5s 0s"
      : "opacity 0.5s 0s, top 0.2s 0.5s"};
  ${media.tablet} {
    width: 31.8vw;
    height: 42.9vw;
    top: ${props => (props.initial ? 0 : props.animate ? 0 : "6vw")};
  }
`

const Image2 = styled.div<{ animate: boolean; initial?: boolean }>`
  position: absolute;
  width: 13.6vw;
  height: auto;
  left: 15.1vw;
  top: 5.2vw;
  filter: brightness(0.92);
  top: ${props => (props.initial ? "5.2vw" : props.animate ? "5.2vw" : "8vw")};
  opacity: ${props => (props.initial ? 1 : props.animate ? 1 : 0)};
  transition-timing-function: cubic-bezier(0.75, 0, 0.25, 1);
  transition: ${props =>
    props.initial
      ? "all 0.9s 0s"
      : props.animate
      ? "all 0.4s 0s"
      : "opacity 0.4s 0s, top 0.4s 0.4s"};
  box-shadow: -6.9vw 0vw 5.6vw rgba(0, 0, 0, 0.04),
    -4.5vw 0vw 3.3vw rgba(0, 0, 0, 0.0303704),
    -2.7vw 0vw 1.8vw rgba(0, 0, 0, 0.0242963),
    -1.4vw 0vw 0.9vw rgba(0, 0, 0, 0.02),
    -0.6vw 0vw 0.5vw rgba(0, 0, 0, 0.0157037),
    -0.1vw 0vw 0.2vw rgba(0, 0, 0, 0.00962963);
  ${media.tablet} {
    width: 19.1vw;
    height: 20.6vw;
    left: 24.4vw;
    top: ${props =>
      props.initial ? "8.9vw" : props.animate ? "8.9vw" : "14vw"};
  }
`

const IssueNo = styled.figcaption<{ visible: boolean }>`
  ${LeviPage.SubTitle1};
  color: ${Colors.charcoal};
  position: absolute;
  transform-origin: "50% 50%";
  transform: rotate(-90deg);
  left: -13vw;
  top: 12vw;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.5s cubic-bezier(0.75, 0, 0.25, 1);

  ${media.tablet} {
    font-size: 1.2vw;
    left: -19vw;
    top: 60vw;
  }
`

const CelebButton = styled.button<{ active: boolean }>`
  opacity: ${props => (props.active ? 1 : 0.2)};
  ${LeviPage.Body1};
  font-weight: bold;
  border: none;
  background: none;
  text-align: left;
  width: 8.1vw;
  margin-right: 4.7vw;
  pointer-events: ${props => (props.active ? "auto" : "none")};
  ${props => props.active && "cursor: pointer;"}
  ${media.tablet} {
    font-size: 1.7vw;
    margin-right: 6.7vw;
    width: 11.4vw;
    overflow: visible;
    white-space: nowrap;
  }
`

const CelebRoster = styled.div`
  position: absolute;
  width: 86.1vw;
  left: 6.9vw;
  top: 47.3vw;
  display: flex;

  span {
    display: flex;

    width: 100%;
  }
  ${media.tablet} {
    :-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }

    span {
      width: fit-content;

      display: flex;
      ${CelebButton}:nth-child(7) {
        margin-right: 3.7vw;
      }
    }

    top: 106.9vw;
    width: 100%;
    overflow: scroll;
    left: 0;
    padding: 0 3.9vw;
    justify-content: flex-start;
  }
`

const Headshot = styled.div<{ isCurrent: boolean }>`
  width: 8.1vw;
  height: 8.1vw;
  margin-bottom: 0.6vw;

  filter: brightness(0.92) grayscale(${props => (props.isCurrent ? 0 : 1)});

  :hover {
    filter: brightness(0.92) grayscale(0);
  }
  transition: 0.4s;

  ${media.tablet} {
    width: 11.4vw;
    height: 11.4vw;
    margin-bottom: 10px;
  }
`

const Name = styled.p`
  margin-bottom: 0;
  white-space: nowrap;
  color: ${Colors.charcoal};
  position: relative;
  ${media.tablet} {
    position: relative;
  }
`

const FiveOhOne = styled(FiveOhOneSVG)`
  width: 1.8vw;
  height: auto;

  ${media.tablet} {
    width: 2.5vw;
  }
  ${media.mobile} {
    width: 6.3vw;
  }
`

const MarqueeText = styled.p`
  ${LeviPage.Body1};
  color: ${Colors.charcoal};
  display: flex;
  align-items: center;
  line-height: 100%;
  width: fit-content;
  white-space: nowrap;
  span {
    margin: 0 1.4vw;
  }

  ${media.tablet} {
    font-size: 1.8vw;
    span {
      margin: 0 2vw;
    }
  }
  ${media.mobile} {
    font-size: 4.3vw;
    line-height: 175%;
    span {
      margin: 0 4.8vw;
      display: flex;
      align-items: center;
    }
  }
`

const Marquee = styled.div`
  position: absolute;
  left: 0;
  bottom: 2.1vw;
  display: flex;
  overflow: hidden;
  align-items: center;
  ${media.tablet} {
    bottom: 2.9vw;
  }
  ${media.mobile} {
    bottom: 7.2vw;
  }
`

const CelebText = styled.p<{ animate: boolean; initial?: boolean }>`
  ${LeviPage.Body2};
  color: ${Colors.charcoal};
  position: absolute;
  width: 17.4vw;
  top: ${props =>
    props.initial ? "21.6vw" : props.animate ? "21.6vw" : "23vw"};
  left: 20.3vw;
  opacity: ${props => (props.initial ? 1 : props.animate ? 1 : 0)};
  transition: ${props =>
    props.initial
      ? "all 1s 0s"
      : props.animate
      ? "all 0.5s 0s"
      : "opacity 0.5s 0s, top 0.5s 0.5s"};
  ${media.tablet} {
    width: 29.5vw;
    font-size: 1.6vw;
    top: ${props => (props.initial ? "46vw" : props.animate ? "46vw" : "50vw")};
    left: 9vw;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  top: 2.4vw;
  right: 2.4vw;
  display: flex;
  align-items: center;
  span {
    ${LeviPage.H3};
    color: ${Colors.charcoal};
  }
  ${FiveOhOne} {
    width: 4.5vw;
    height: auto;
    margin-left: 1.1vw;
  }
  ${media.tablet} {
    ${FiveOhOne} {
      width: 6.3vw;
      margin-left: 2vw;
    }
    span {
      font-size: 1.8vw;
    }
  }
  ${media.mobile} {
    position: initial;
    margin: 0 auto 60px;
    justify-content: center;
    span {
      font-size: 4.3vw;
    }
    ${FiveOhOne} {
      width: 16vw;
      margin-left: 4vw;
    }
  }
`

const CelebCard = styled.div<{ initial: boolean }>`
  position: absolute;
  top: 6.9vw;
  left: 6.9vw;
  /* opacity: ${props => (props.initial ? 0 : 1)}; */
  ${media.tablet} {
    top: 10.3vw;
    left: 7.4vw;
  }
`

const TokenWrap = styled.div`
  width: 20.9vw;
  max-height: fit-content;
  position: absolute;
  ${media.tablet} {
  }
`

const FragmentWrap = styled.div<{
  visible: boolean
  initial?: boolean
  dual: boolean
}>`
  height: 32.5vw;
  width: 47.1vw;
  position: absolute;
  top: ${props => (props.initial ? "7vw" : props.visible ? "7vw" : "10vw")};
  right: 7vw;
  opacity: ${props => (props.initial ? 1 : props.visible ? 1 : 0)};
  transition: all ${props => (props.initial ? "0.7s" : "0.5s 0s")};
  ${props => (props.initial || props.visible ? "" : "z-index: -1;")}
  ${TokenWrap}:nth-child(1) {
    ${props =>
      props.dual
        ? `
      left: 0;
      top: 0
    `
        : `
      left: 165px;
      top: 35px;
    `}
  }
  ${TokenWrap}:nth-child(2) {
    top: initial;
    bottom: 0;
    right: 0;
  }

  ${media.tablet} {
    right: 7.9vw;
    width: 41.1vw;
    top: ${props =>
      props.initial ? "16.9vw" : props.visible ? "16.9vw" : "20vw"};
    ${TokenWrap}:nth-child(1) {
      ${props =>
        props.dual
          ? `
      left: 11.8vw;
      top: 0
    `
          : `
      left: 4.1vw;
      top: 42.6vw;
    `}
    }
    ${TokenWrap}:nth-child(2) {
      left: 0;
      top: ${props =>
        props.initial ? "42.6vw" : props.visible ? "42.6vw" : "50.6vw"};
      right: auto;
    }
  }
`

const MobileSection = styled.section`
  display: none;
  ${media.mobile} {
    display: initial;
  }
`

const MobileTokenPlaceholder = styled.div<{ dual: boolean }>`
  height: ${props => (props.dual ? "234vw" : "111vw")};
  margin-bottom: 130px;
`

const MobileImage = styled.div`
  position: absolute;
  height: auto;
  width: 37.7vw;
  overflow: hidden;
  right: 0;
  top: 20.8vw;
  > div {
    height: 100%;
  }
`

const MobileTitleWrapper = styled.div<{ width: string; indented: boolean }>`
  position: relative;
  width: ${props => props.width};
  ${Title}:nth-child(1) {
    text-align: ${props => (props.indented ? "left" : "right")};
  }
  ${Title}:nth-child(2) {
    text-align: ${props => (props.indented ? "right" : "left")};
  }
`

const MobileCelebText = styled.p`
  ${LeviPage.Body2}
  color: ${Colors.charcoal};
  font-size: 3.9vw;
  letter-spacing: 0.1vw;
  margin-bottom: 50px;
  width: 45.4vw;
`

const MobileCelebCard = styled.div`
  position: relative;
  padding-top: 20px;
  &:not(:nth-of-type(5)):not(:last-of-type) {
    ${MobileTitleWrapper} {
      margin-left: 7.2vw;
    }
  }
  &:nth-of-type(5) {
    ${Title}:first-of-type {
      text-align: right;
    }
    ${Title}:last-of-type {
      text-align: left;
    }
  }
`

const MobileLink = styled.h1<{ current?: boolean; active: boolean }>`
  color: ${props => (props.current ? LevisColors.redDark : Colors.charcoal)};
  font: 700 5.8vw "Interstate";
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 10.9vw;
  ${props =>
    !props.current &&
    `
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-position: under;
  `}
  ${props =>
    !props.active &&
    `
    text-decoration: none;
    opacity: 0.2;
  `}
`

const DropUp = styled.div`
  background: ${Colors.offWhite};
  border-top: 1px solid ${Colors.charcoal};
  height: 136.5vw;
  top: 0;
  width: 100%;
  padding: 10vw 5vw 0;
  position: absolute;
  transition: top 600ms cubic-bezier(0.75, 0, 0.25, 1);
`

const MobileNavSelected = styled.div`
  background: ${Colors.offWhite};
  padding: 7.5vw 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${Colors.charcoal};
  position: relative;
`

const SelectedCeleb = styled.h1`
  color: ${Colors.charcoal};
  font: 700 5.5vw "Interstate";
  letter-spacing: 0.1vw;
  line-height: 120%;
  text-transform: uppercase;
  transition: all 600ms cubic-bezier(0.75, 0, 0.25, 1);
`

const NavTriangle = styled(MobileNavArrowSVG)`
  width: 3.6vw;
  height: auto;
  margin-left: 3.9vw;
  transition: all 600ms cubic-bezier(0.75, 0, 0.25, 1);
  path {
    transition: all 600ms cubic-bezier(0.75, 0, 0.25, 1);
  }
`

const MobileNav = styled.nav<{ open: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: none;
  ${media.mobile} {
    display: initial;
  }
  ${props =>
    props.open &&
    `
    ${SelectedCeleb} {
      color: ${LevisColors.redDark};
    }
    ${DropUp} {
      top: -136.5vw;
    }
    ${NavTriangle} {
      transform: rotate(180deg);
      path {
        fill: ${LevisColors.redDark}
      }
    }
  `}
`

export default Hero
