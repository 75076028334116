import React, { useEffect, useState } from "react"
import { Levis as Colors } from "src/styles/Colors"
import { LeviPage as Text } from "components/Text"
import styled from "styled-components"
import media from "src/styles/media"

type Props = {
  product: {
    auctions?: [
      {
        endsAt: string
        info: {
          bids: [{ amount: string }]
          minBid: string
        }
      }
    ]
    prices?: [
      {
        currency: string
        price: string
      }
    ]
    tokenMetadata: {
      animation_url?: string
      image: string
      name: string
    }
    liveAt: string
    salesEndAt: string
    purchaseLink: string
  }
  current: boolean
}

const LevisTokenViewer: React.FC<Props> = ({ product, current }) => {
  const [currentTime, setCurrentTime] = useState(new Date())

  useEffect(() => {
    let interval = setInterval(() => setCurrentTime(new Date()), 1000)
    return () => clearInterval(interval)
  }, [])

  const { auctions, prices, tokenMetadata, liveAt, salesEndAt, purchaseLink } =
    product

  const getTokenType = (): string =>
    auctions[0] ? "live auction" : "open edition"

  const getAuction = () => {
    return auctions[0] ? auctions[0] : null
  }

  const getColor = (): string => (getAuction() ? "blue" : "red")

  const getHighestBid = (): number => {
    let auction = getAuction()
    const { bids, minBid } = auction.info
    let min = (auction && parseInt(minBid, 10)) || 0
    let bidAmounts = (bids || []).map(bid => bid.amount)
    return Math.max(...[min, ...bidAmounts])
  }

  const getPurchaseTimeRemaining = (): string => {
    let dSeconds =
      (new Date(salesEndAt).getTime() - currentTime.getTime()) / 1000
    if (dSeconds <= 1) return "00:00:00"
    else {
      let hours = Math.floor(dSeconds / (60 * 60))
        .toString()
        .padStart(2, "0")
      let minutes = Math.floor((dSeconds / 60) % 60)
        .toString()
        .padStart(2, "0")
      let seconds = Math.floor(dSeconds % 60)
        .toString()
        .padStart(2, "0")
      return `${hours}:${minutes}:${seconds}`
    }
  }

  const formatTimeLeft = () =>
    getPurchaseTimeRemaining() === "00:00:00"
      ? "ended"
      : `${getPurchaseTimeRemaining()} remaining`

  const getPrice = (): number =>
    Number(prices.find(price => price.currency === "USD").price)

  const getFormattedPrice = () =>
    Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
      .format(auctions[0] ? getHighestBid() : getPrice())
      .replace(/\D00(?=\D*$)/, "")

  const getUnavailability = (): boolean =>
    getPurchaseTimeRemaining() === "00:00:00" ||
    currentTime.getTime() < new Date(liveAt).getTime()

  const getUrgency = (): boolean => {
    let timeArray = getPurchaseTimeRemaining().split(":")
    return Number(timeArray[0]) === 0 && Number(timeArray[1]) < 5
  }

  return (
    <Wrapper
      unavailable={getUnavailability()}
      urgent={getUrgency()}
      color={getColor()}
      href={purchaseLink}
      visible={current}
    >
      <Token>
        <video
          autoPlay
          loop
          muted
          playsInline
          poster={tokenMetadata.image}
          key={tokenMetadata.animation_url}
          // onLoad={passCurrent}
        >
          <source src={tokenMetadata.animation_url} />
          Your browser does not support the video tag.
        </video>
      </Token>
      <TypeContainer>
        <TypeText>{getTokenType()}</TypeText>
        <TypeText>{formatTimeLeft()}</TypeText>
      </TypeContainer>
      <TitleContainer>
        <Title>{tokenMetadata.name}</Title>
        <Price>{getFormattedPrice()}</Price>
      </TitleContainer>
    </Wrapper>
  )
}

const Token = styled.div`
  height: 20.9vw;
  width: 20.9vw;
  border-radius: 2px;
  /* box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1); */
  margin-bottom: 1vw;
  transition: transform 300ms ease, box-shadow 300ms ease;
  transform-origin: bottom center;
  video {
    height: 100%;
    width: 100%;
  }
  ${media.tablet} {
    width: 29.3vw;
    height: 29.3vw;
    margin-bottom: 1.5vw;
    box-shadow: none;
  }
  ${media.mobile} {
    width: 100%;
    height: 90vw;
    margin-bottom: 15px;
    box-shadow: none;
  }
`

const TypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  padding: 0.5vw 0.7vw;
  margin-bottom: 1vw;
  transform: scale(1);
  ${media.tablet} {
    padding: 0.7vw 1vw;
    margin-bottom: 1.5vw;
    width: 100%;
  }
  ${media.mobile} {
    padding: 1.7vw 2.4vw;
    margin-bottom: 15px;
  }
`

const TypeText = styled.h1`
  ${Text.NFTInfo}
  ${media.tablet} {
    font-size: 1.2vw;
  }
  ${media.mobile} {
    font-size: 2.9vw;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Title = styled.h1`
  ${Text.NFTTitle}
  ${media.tablet} {
    font-size: 2vw;
  }
  ${media.mobile} {
    font-size: 4.8vw;
  }
`

const Price = styled(Title)`
  min-width: fit-content;
  margin-left: 1vw;
`

const Wrapper = styled.a<{
  color: string
  unavailable: boolean
  urgent: boolean
  visible: boolean
}>`
  appearance: auto;
  width: 20.9vw;
  max-height: fit-content;
  text-transform: uppercase;
  ${Token}, ${TypeContainer} {
    border: 1px solid
      ${props =>
        props.unavailable
          ? "#57575A"
          : props.color === "red"
          ? Colors.redDark
          : Colors.blueDark};
  }
  ${Token} {
    ${props =>
      !props.unavailable &&
      `
      &:hover {
        transform: scale(1.07);
        // box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
      }
      ${media.mobile} {
        &:hover {
          transform: none;
        }
      }
    `}
  }
  ${TypeContainer} {
    background: ${props =>
      props.unavailable
        ? "#CDCDCD"
        : props.color === "red"
        ? props.urgent
          ? Colors.redDark
          : Colors.redLight
        : props.urgent
        ? Colors.blueDark
        : Colors.blueLight};
    color: ${props =>
      props.unavailable
        ? "#57575A"
        : props.urgent
        ? "white"
        : props.color === "red"
        ? Colors.redDark
        : Colors.blueDark};
  }
  ${props =>
    props.unavailable &&
    `
    video {
      filter: grayscale(1);
    }
  `}
  ${media.tablet} {
    width: 29.3vw;
  }

  ${media.mobile} {
    width: 100%;
    display: block;
    margin-bottom: ${props => (props.color === "blue" ? "50px" : "110px")};
  }
`

export default LevisTokenViewer
