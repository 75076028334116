import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { ReactComponent as LogoSVG } from "svg/levisLogo.svg"

import { NavButton } from "./Buttons"
import mouse from "svg/mouseArrow.svg"
import pointer from "svg/pointer.svg"
import media from "src/styles/media"
import Colors from "src/styles/Colors"
import { LeviPage } from "src/components/Text"

const LevisFooter: React.FC<{}> = () => {
  return (
    <Wrapper>
      <MainContent>
        <Link aria-label="home" to="/">
          <Logo />
        </Link>
        {/* <Button>Log In</Button> */}
      </MainContent>
      <Line>
        © Bitski.
        <LegalLinks>
          <LinkExternal
            href="https://www.notion.so/TOS-2-1-21-1f9a98659e6446479479339a23185d2f"
            target="_blank"
            rel="noopener noreferrer"
          >
            TERMS OF USE
          </LinkExternal>
          <LinkExternal
            href="https://www.notion.so/Privacy-Policy-c166160b6c544e909dd04176f43081d6"
            target="_blank"
            rel="noopener noreferrer"
          >
            PRIVACY POLICY
          </LinkExternal>
        </LegalLinks>
      </Line>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  height: 18.8vw;
  display: flex;
  flex-direction: column;
  background: ${Colors.cryptoWhite};
  border-top: solid 1px ${Colors.charcoal};
  padding: 6.2vw 6.94vw 30px;
  ${media.tablet} {
    height: auto;
    padding: 8.3vw 9.8vw 2.4vw;
  }
  ${media.mobile} {
    height: 106.5vw;
    padding: 13.8vw 5vw 46vw;
  }
`
const MainContent = styled.div`
  display: flex;
  align-items: flex-start;
  ${media.tablet} {
    flex-wrap: wrap;
  }
`

const Logo = styled(LogoSVG)`
  width: 17.7vw;
  ${media.tablet} {
    width: 25.1vw;
  }
  ${media.mobile} {
    width: 61.6vw;
  }
`

const LinkExternal = styled.a`
  color: ${Colors.charcoal};
  text-decoration: underline;
`

// const Button = styled(NavButton)`
//   width: 10vw;
//   height: 2.6vw;
//   text-transform: uppercase;
//   margin-left: auto;
//   background: ${Colors.charcoal};
//   color: ${Colors.cryptoWhite};
//   font-family: "Neue Machina";
//   line-height: 2.6vw;
//   ${media.tablet} {
//     order: 4;
//     width: 100%;
//     height: 12.1vw;
//     margin-bottom: 18.1vw;
//   }
// `

const Line = styled.div`
  width: 86.1vw;
  border-top: solid 1px ${Colors.charcoal};
  color: ${Colors.charcoal};
  margin-top: auto;
  display: flex;
  padding-top: 0.9vw;
  justify-content: space-between;
  font-size: 0.7vw;
  ${media.tablet} {
    ${LeviPage.NFTInfo};
    color: ${Colors.charcoal};
    width: 100%;
    margin-top: 9.8vw;
    padding-top: 1.2vw;
    font-size: 1.2vw;
  }
  ${media.mobile} {
    padding-top: 7.2vw;
    margin-top: auto;
    font-size: 2.4vw;
    text-transform: capitalize;
    font-weight: bold;
  }
`
const LegalLinks = styled.div`
  display: flex;
  width: 12.9vw;
  justify-content: space-between;
  ${media.tablet} {
    ${LeviPage.NFTInfo};
    width: 23.4vw;
    font-size: 1.2vw;
    ${LinkExternal} {
      font-weight: bold;
    }
  }

  ${media.mobile} {
    width: 42.5vw;
  }
  ${media.mobile} {
    width: auto;
    > :first-child {
      margin-right: 20px;
    }
  }
`
export default LevisFooter
