import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { ResizeProvider } from "../context/ResizeContext"
import SEO from "components/seo"
import Hero from "src/sections/levis/Hero"
import Footer from "components/LevisFooter"

import mouse from "svg/mouseArrow.svg"
import pointer from "svg/pointer.svg"
import media from "../styles/media"

const Levis = ({ data }) => (
  <ResizeProvider>
    <Main>
      <SEO title="Levi's X Bitski" />
      <Hero data={data} />
    </Main>
    <Footer />
  </ResizeProvider>
)

const Main = styled.main`
  height: calc(100vh - 200px);
  min-height: 73.3vw;
  width: 100vw;
  max-width: 100%;
  ${media.tablet} {
    height: auto;
    width: 100vw;
  }
  ${media.mobile} {
    height: auto;
  }
`

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2800, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    naomi1: file(relativePath: { eq: "naomiOsaka1.jpg" }) {
      ...fluidImage
    }
    naomi2: file(relativePath: { eq: "naomiOsaka2.jpg" }) {
      ...fluidImage
    }
    naomiHS: file(relativePath: { eq: "naomiOsakaHS.jpg" }) {
      ...fluidImage
    }
    naomiM: file(relativePath: { eq: "naomi-Mobile.jpg" }) {
      ...fluidImage
    }
    marcusHS: file(relativePath: { eq: "marcusHS.jpg" }) {
      ...fluidImage
    }
    haileyB1: file(relativePath: { eq: "haileyB1.jpg" }) {
      ...fluidImage
    }
    haileyB2: file(relativePath: { eq: "haileyB2.jpg" }) {
      ...fluidImage
    }
    haileyBHS: file(relativePath: { eq: "haileyBHS.jpg" }) {
      ...fluidImage
    }
    haileyM: file(relativePath: { eq: "hailey-Mobile.jpg" }) {
      ...fluidImage
    }
    jadenSHS: file(relativePath: { eq: "jadenSHS.jpg" }) {
      ...fluidImage
    }
    jadenS1: file(relativePath: { eq: "jadenS1.jpg" }) {
      ...fluidImage
    }
    jadenS2: file(relativePath: { eq: "jadenS2.jpg" }) {
      ...fluidImage
    }
    jadenM: file(relativePath: { eq: "jaden-Mobile.jpg" }) {
      ...fluidImage
    }
    emmaCHS: file(relativePath: { eq: "emmaCHS.jpg" }) {
      ...fluidImage
    }
    emmaC1: file(relativePath: { eq: "emmaC1.jpg" }) {
      ...fluidImage
    }
    emmaC2: file(relativePath: { eq: "emmaC2.jpg" }) {
      ...fluidImage
    }
    emmaM: file(relativePath: { eq: "emma-Mobile.jpg" }) {
      ...fluidImage
    }
    shaiGHS: file(relativePath: { eq: "shaiGHS.jpg" }) {
      ...fluidImage
    }
    shaiG1: file(relativePath: { eq: "shaiG1.jpg" }) {
      ...fluidImage
    }
    shaiG2: file(relativePath: { eq: "shaiG2.jpg" }) {
      ...fluidImage
    }
    shaiM: file(relativePath: { eq: "shai-Mobile.jpg" }) {
      ...fluidImage
    }
    barbieFHS: file(relativePath: { eq: "barbieFHS.jpg" }) {
      ...fluidImage
    }
    barbieF1: file(relativePath: { eq: "barbieF1.jpg" }) {
      ...fluidImage
    }
    barbieF2: file(relativePath: { eq: "barbieF2.jpg" }) {
      ...fluidImage
    }
    barbieM: file(relativePath: { eq: "barbie-Mobile.jpg" }) {
      ...fluidImage
    }
  }
`

export default Levis
